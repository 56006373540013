import { Button, Card, Row, Col } from "@mui/material";
import React, { useEffect, useState } from "react";
import { en } from "../language/en";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./layout/Header";
import { TablePagination } from "@mui/material";
import Table from "react-bootstrap/Table";
import { Link, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { BsFillArrowRightSquareFill, BsPencil, BsEye } from "react-icons/bs";
import { LETTER_PROGRESS } from "../utils/Constants";
import {
  LetterListScheduler,
  LetterSelector,
} from "../store/reducer/LetterReducer";
import { CardBody } from "react-bootstrap";
const Letter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  useEffect(() => {
    dispatch(LetterListScheduler({ page, limit, query }));
  }, []);

  const addLetter = () => {
    navigate("/AddLetter/step1");
  };

  const { letterListDatas, letterListLoading } = useSelector(LetterSelector);
  useEffect(() => {
    console.log(letterListDatas, "letterListDatas1");
  }, [letterListDatas]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setRowsPerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let count = letterListDatas?.count % 10;
    let remainder = letterListDatas?.count / 10;
    let pageLength = remainder > 0 ? count + 1 : count;
    setPageSize(pageLength);
  }, [letterListDatas]);

  useEffect(() => {
    dispatch(LetterListScheduler({ page, limit, query }));
  }, [page, limit, query]);

  return (
    <>
      <Header />
      <div className="d-flex justify-content-between">
        <h1>{en.letters}</h1>
        {localStorage.getItem("role") != 1 ? (
          <>
            {" "}
            <Button variant="contained" onClick={addLetter}>
              {en.add}
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* Standard Start */}
      <section className="scroll-section mt-2" id="standard">
        {/* <h2 className="small-title">Standard</h2> */}
        <Card body className="mb-5">
          <CardBody>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{en.s_no}</th>
                  <th>
                    {en.letter} {en.no}
                  </th>
                  <th>
                    {en.letter} {en.date}
                  </th>
                  <th>{en.subject}</th>
                  <th>{en.status}</th>
                  <th>{en.action}</th>
                </tr>
              </thead>
              <tbody>
                {letterListDatas &&
                  letterListDatas?.rows?.map((letterValues, index) => {
                    return (
                      <>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{letterValues?.letter_no}</td>
                          <td>
                            {dayjs(letterValues?.letter_date).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>{letterValues?.subject}</td>
                          <td>{LETTER_PROGRESS[letterValues?.status]}</td>
                          <td>
                            {role == 2 && letterValues?.status != 2 ? (
                              <>
                                <Link
                                  to={`/letter_proceed/${letterValues.id}`}
                                  title="Update Progress"
                                  className="btn btn-primary mx-1 "
                                >
                                  <BsFillArrowRightSquareFill />
                                </Link>
                                <Link
                                  to={`/letter/edit/${letterValues.id}`}
                                  title="Edit"
                                  className="btn btn-primary mx-1 "
                                >
                                  <BsPencil />
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to={`/letter/view/${letterValues.id}`}
                                  title="View"
                                  className="btn btn-primary mx-1 "
                                >
                                  <BsEye />
                                </Link>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </CardBody>
          {/* List Header Start */}

          <div className="d-flex justify-content-between px-2">
            <strong>
              {en.total} {en.count} : {letterListDatas?.count}
            </strong>
            <TablePagination
              component="div"
              count={letterListDatas?.count}
              page={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Card>
      </section>
    </>
  );
};

export default Letter;
