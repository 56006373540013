import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants.js";
import {
  createLetter,
  editReffer,
  letterById,
  letterNo,
  editletter,
  letterList,
  completeProgress,
  download_attachment
} from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "Letter";

const initialState = {
  loading: "initial",
  errorMessage: "",
  letter_no: null,
  letterNumberLoading: null,
  addLetterLoader: null,
  hiringPartnerletter_no: null,
  letterData: [],
  ccmentorletter_no: [],
  letterListDatas: [],
  letterListLoading: "initial",
  signInLoading: "initial",
  profileLoading: "initial",
  LetterByIdLoader: "initial",
  editRefferLoader: "initial",
  ccmentorSignInLoad: "initial",
  updateSchedulerLoading: "initial",
  forgotPasswordLoading: "initial",
  adminsignInLoading: "initial",
  completeProgressLoader: "initial",
  editLetterLoader: "initial",
  profileData: null,
  count: 0,
};

export const getGeneratedLetterNo = createAsyncThunk(
  `${namespace}/getGeneratedLetterNo`,
  async ({}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await letterNo();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addLetterScheduler = createAsyncThunk(
  `${namespace}/addLetterScheduler`,
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await createLetter(formData, id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const editRefferScheduler = createAsyncThunk(
  `${namespace}/editRefferScheduler`,
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await editReffer(formData, id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const LetterById = createAsyncThunk(
  `${namespace}/LetterById`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await letterById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const LetterListScheduler = createAsyncThunk(
  `${namespace}/LetterListScheduler`,
  async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await letterList(page, limit, query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const completeProgressScheduler = createAsyncThunk(
  `${namespace}/completeProgressScheduler`,
  async ({ formData}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await completeProgress(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const editLetterScheduler = createAsyncThunk(
  `${namespace}/editLetterScheduler`,
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await editletter(formData, id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const downloadAttachment = createAsyncThunk(
  `${namespace}/downloadAttachment`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData,"DOWNLOADSDFFDG");
      const { data } = await download_attachment(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const LetterSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearLetterdata: () => {
      return initialState;
    },
    clearLetterData: (state) => {
      state.letter_no = null;
      state.letterNumberLoading = "initial";
      state.addLetterLoader = null;
      state.LetterByIdLoader = "initial";
      state.adminsignInLoading = "initial";
      state.errorMessage = null;
      state.hiringPartnerletter_no = null;
      state.editRefferLoader = "initial";
      state.ccmentorSignInLoad = "initial";
      state.forgotPasswordLoading = "initial";
      state.letterListDatas = [];
      state.letterListLoading = "initial";
      state.completeProgressLoader = "initial";
      state.editLetterLoader = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getGeneratedLetterNo.pending, (state) => {
      state.letterNumberLoading = API_STATUS.PENDING;
    });
    builder.addCase(getGeneratedLetterNo.fulfilled, (state, { payload }) => {
      console.log(payload, "generated payload payload");
      state.letterNumberLoading = API_STATUS.FULFILLED;
      state.letter_no = payload?.letter_no;
    });
    builder.addCase(getGeneratedLetterNo.rejected, (state, action) => {
      state.letterNumberLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(addLetterScheduler.pending, (state) => {
      state.addLetterLoader = API_STATUS.PENDING;
    });
    builder.addCase(addLetterScheduler.fulfilled, (state, { payload }) => {
      state.addLetterLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(addLetterScheduler.rejected, (state, action) => {
      state.addLetterLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(editRefferScheduler.pending, (state) => {
      state.editRefferLoader = API_STATUS.PENDING;
    });
    builder.addCase(editRefferScheduler.fulfilled, (state, { payload }) => {
      state.editRefferLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(editRefferScheduler.rejected, (state, action) => {
      state.editRefferLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(LetterById.pending, (state) => {
      state.LetterByIdLoader = API_STATUS.PENDING;
    });
    builder.addCase(LetterById.fulfilled, (state, { payload }) => {
      state.LetterByIdLoader = API_STATUS.FULFILLED;
      console.log(payload, "getById Payload");
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.letterData = payloadData?.data;
    });
    builder.addCase(LetterById.rejected, (state, action) => {
      state.LetterByIdLoader = API_STATUS.REJECTED;
    });
    builder.addCase(LetterListScheduler.pending, (state) => {
      state.letterListLoading = API_STATUS.PENDING;
    });
    builder.addCase(LetterListScheduler.fulfilled, (state, { payload }) => {
      console.log(payload, "LetterListScheduler1");
      state.letterListLoading = API_STATUS.FULFILLED;
      console.log(payload, "payload payload");
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.datas, secretKey)
      );
      state.letterListDatas = payloadData;
    });
    builder.addCase(LetterListScheduler.rejected, (state, action) => {
      state.letterListLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(completeProgressScheduler.pending, (state) => {
      state.completeProgressLoader = API_STATUS.PENDING;
    });
    builder.addCase(completeProgressScheduler.fulfilled, (state, { payload }) => {
      state.completeProgressLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(completeProgressScheduler.rejected, (state, action) => {
      state.completeProgressLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(editLetterScheduler.pending, (state) => {
      state.editLetterLoader = API_STATUS.PENDING;
    });
    builder.addCase(editLetterScheduler.fulfilled, (state, { payload }) => {
      state.editLetterLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(editLetterScheduler.rejected, (state, action) => {
      state.editLetterLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(downloadAttachment.pending, (state) => {
      state.LetterByIdLoader = API_STATUS.PENDING;
    });
    builder.addCase(downloadAttachment.fulfilled, (state, { payload }) => {
      state.LetterByIdLoader = API_STATUS.FULFILLED;
    });
    builder.addCase(downloadAttachment.rejected, (state, action) => {
      state.LetterByIdLoader = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearLetterdata, clearLetterData } = LetterSlice.actions;

export const LetterSelector = (state) => state.Letter;

export default LetterSlice.reducer;
