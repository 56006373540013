export const DIMENSION = {
  Desktop: "desktop",
  Tablet: "tablet",
  Mobile: "mobile",
};

export const LAYOUT = {
  Fluid: "fluid",
  Boxed: "boxed",
};

export const RADIUS = {
  Rounded: "rounded",
  Standard: "standard",
  Flat: "flat",
};

export const THEME_COLOR = {
  LightBlue: "light-blue",
  DarkBlue: "dark-blue",
  LightRed: "light-red",
  DarkRed: "dark-red",
  LightGreen: "light-green",
  DarkGreen: "dark-green",
  LightPurple: "light-purple",
  DarkPurple: "dark-purple",
  LightPink: "light-pink",
  DarkPink: "dark-pink",
};

export const NAV_COLOR = {
  Default: "default",
  Light: "light",
  Dark: "dark",
};
export const MENU_PLACEMENT = {
  Vertical: "vertical",
  Horizontal: "horizontal",
};
export const MENU_BEHAVIOUR = {
  Pinned: "pinned",
  Unpinned: "unpinned",
};

export const USER_ROLE = {
  Admin: "admin",
  Editor: "editor",
};

export const API_STATUS = Object.freeze({
  PENDING: "Pending",
  REJECTED: "Rejected",
  FULFILLED: "Fulfilled",
  NETWORK_ERROR: "Network Error",
});
export const JOBSTATUS = Object.freeze({
  0: "In active",
  1: "Hold",
  2: "Approve",
  3: "Removed",
});

export const COURSE_STATUS = Object.freeze({
  0: "Start",
  1: "In-Progress",
  2: "Completed",
});

export const EODSTATUS = Object.freeze({
  1: "Completed",
  2: "In-Progress",
  3: "Denied",
  4: "APPROVED",
});

export const STUDENT_ACCEPTANCE_STATUS = Object.freeze({
  0: "Not Yet Accepted",
  1: "Accepted",
  2: "Rejected",
  3: "Assigned to Mentor",
});

export const TYPE_OF_WORK = Object.freeze({
  1: "Coding",
  2: "Research",
  3: "Learning",
  4: "Project Implementation",
  5: "Meeting",
  6: "Mentor Discussion",
  7: "Team Meeting",
  8: "Execution",
});

export const FEEDBACK_STATUS = Object.freeze({
  1: "Accepted",
  2: "Rejected",
});

export const ADMIN_ACCEPTANCE_STATUS = Object.freeze({
  0: "Not yet attended",
  1: "Attended and waiting for result",
  2: "Approved and Yet to Assign CC Mentor",
  3: "Mentor Assigned",
});

export const ADMIN_ACCEPTANCE_COLOR = Object.freeze({
  0: "danger",
  1: "warning",
  2: "primary",
  3: "success",
});

export const TYPE_OF_ACTIVITY = Object.freeze({
  1: "Technical",
  2: "Non-Technical",
});

export const JOB_STATUS = Object.freeze({
  0: "Inactive",
  1: "Hold",
  2: "Approved",
  3: "Removed",
});

export const QUESTION_TYPE = Object.freeze({
  1: "Textarea",
  2: "Text",
  3: "Select",
  4: "Radio",
  5: "Checkbox",
});

export const QUESTION_GROUP = Object.freeze({
  1: "First",
  2: "Second",
});

export const LETTER_PROGRESS = Object.freeze({
  0: "In Progress",
  1: "Refered",
  2: "Completed",
});

export const dashBoardLabel = Object.freeze({
  InprogressLetters: "In-progress Letters",
  CompleteLetters: "Completed Letters",
  ReferLetters: "Reffered Letters",
  overDueLetters: "Overdue Letters",
});
