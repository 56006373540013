import React, { useEffect, useState } from "react";
import Header from "./layout/Header";
import {
  Button,
  Row,
  Col,
  Card,
  Dropdown,
  Badge,
  CardBody,
} from "react-bootstrap";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardStat,
  dashboardSelector,
} from "../store/reducer/DashboardReducer";
import Table from "react-bootstrap/Table";
import { Doughnut } from "react-chartjs-2";
import { DoughnutChart } from "./DoughnutChart";
import {
  LetterSelector,
  LetterListScheduler,
} from "../store/reducer/LetterReducer";
import dayjs from "dayjs";
import { LETTER_PROGRESS, dashBoardLabel } from "../utils/Constants";
import { Link } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { en } from "../language/en";
import _ from "lodash";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { letterListDatas, letterListLoading } = useSelector(LetterSelector);
  const [filteredData, setFilterData] = useState();
  console.log(filteredData, "filterData");
  const { dashboardData } = useSelector(dashboardSelector);

  useEffect(() => {
    dispatch(LetterListScheduler({ page: 1, limit: 10, query: "overdue" }));
    dispatch(DashboardStat({}));
  }, []);

  useEffect(() => {
    if (Object.keys(dashboardData) != 0) {
      let filteredData = Object.fromEntries(
        Object.entries(dashboardData?.data).filter(
          ([key]) => key !== "TotalLetters"
        )
      );
      setFilterData(filteredData);
    }
  }, [dashboardData]);

  const data = {
    labels: filteredData
      ? [
          dashBoardLabel.InprogressLetters,
          dashBoardLabel.ReferLetters,
          dashBoardLabel.overDueLetters,
          dashBoardLabel.CompleteLetters,
        ]
      : ["Red", "Blue", "Yellow", "Green"],
    datasets: [
      {
        label: "# of Counts",
        data: filteredData
          ? [
              filteredData?.InprogressLetters,
              filteredData?.ReferLetters,
              filteredData?.overDueLetters,
              filteredData?.CompleteLetters,
            ]
          : [12, 19, 3, 5],
        backgroundColor: [" #ebb71a", " #279aac", "#cf2637", " #439b38"],
        borderColor: [" #ebb71a", " #279aac", " #cf2637", " #439b38"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Header />
      <div>{en.Dashboards}</div>

      <div className="mt-5 mb-5">
        <Row className="g-2">
          <Col sm="3">
            <Card className="sh-11 hover-scale-up cursor-pointer">
              <Card.Body className="h-100 py-3 align-items-center">
                <Row className="g-0 h-100 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="bg-warning sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons
                        icon="navigate-diagonal"
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col>
                    <Row className="gx-2 d-flex align-content-center">
                      <Col xs="12" className="col-12 d-flex">
                        <div className="d-flex align-items-center lh-1-25 text-warning">
                          In-progress Letters
                        </div>
                      </Col>
                      <Col xl="auto" className="col-12">
                        <div className="cta-5 text-warning  display-3">
                          {dashboardData?.data?.InprogressLetters}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="3">
            <Card className="sh-11 hover-scale-up cursor-pointer">
              <Card.Body className="h-100 py-3 align-items-center">
                <Row className="g-0 h-100 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="bg-info sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons
                        icon="sync-horizontal"
                        className="text-white"
                      />
                    </div>
                  </Col>
                  <Col>
                    <Row className="gx-2 d-flex align-content-center">
                      <Col xs="12" className="col-12 d-flex">
                        <div className="d-flex align-items-center lh-1-25 text-info">
                          Reffered Letters
                        </div>
                      </Col>
                      <Col xl="auto" className="col-12">
                        <div className="cta-5 text-info  display-3">
                          {dashboardData?.data?.ReferLetters}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="sh-11 hover-scale-up cursor-pointer">
              <Card.Body className="h-100 py-3 align-items-center">
                <Row className="g-0 h-100 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="bg-danger sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="alarm" className="text-white" />
                    </div>
                  </Col>
                  <Col>
                    <Row className="gx-2 d-flex align-content-center">
                      <Col xs="12" className="col-12 d-flex">
                        <div className="d-flex align-items-center text-danger lh-1-25">
                          Overdue Letters
                        </div>
                      </Col>
                      <Col xl="auto" className="col-12">
                        <div className="cta-5 text-danger  display-3">
                          {" "}
                          {dashboardData?.data?.overDueLetters}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="3">
            <Card className="sh-11 hover-scale-up cursor-pointer">
              <Card.Body className="h-100 py-3 align-items-center">
                <Row className="g-0 h-100 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="bg-success sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="check" className="text-white" />
                    </div>
                  </Col>
                  <Col>
                    <Row className="gx-2 d-flex align-content-center">
                      <Col xs="12" className="col-12 d-flex">
                        <div className="d-flex align-items-center lh-1-25 text-success">
                          Completed Letters
                        </div>
                      </Col>
                      <Col xl="auto" className="col-12">
                        <div className="cta-5 text-success display-3">
                          {dashboardData?.data?.CompleteLetters}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Row className="g-2">
        <Col sm="5">
          <div>
            <Card className="p-5">
              <DoughnutChart props={data} />
            </Card>
          </div>
        </Col>
        <Col sm="7">
          <Card>
            <CardBody>
              <h1>Overdue Letter's</h1>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{en.s_no}</th>
                    <th>Letter No</th>
                    <th>Letter Date</th>
                    <th>Subject</th>
                  </tr>
                </thead>
                <tbody>
                  {letterListDatas &&
                    letterListDatas?.rows?.map((letterValues, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{letterValues?.letter_no}</td>
                            <td>
                              {dayjs(letterValues?.letter_date).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{letterValues?.subject}</td>
                            {/* <td>{LETTER_PROGRESS[letterValues?.status]}</td> */}
                            {/* <td>
                              <Link
                                to={`/EditLetter/${letterValues.id}`}
                                title="Edit"
                                className="btn btn-primary mx-1 "
                              >
                                <BsPencil />
                              </Link>
                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
