import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { signUp } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";

const namespace = "signUpConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signUpData: null,
  signUpLoading: "initial",
  userExistLoading: "initial",
  collegeLoading: "initial",
  collegeData: null,
  count: 0,
};

export const signUpScheduler = createAsyncThunk(
  `${namespace}/signUpScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "signUpScheduler");
      const { data } = await signUp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// export const checkUserExist = createAsyncThunk(
//   `${namespace}/checkUserExist`,
//   async ({ formData }, { rejectWithValue, dispatch }) => {
//     try {
//       const { data } = await checkUser(formData);
//       return data;
//     } catch (error) {
//       return rejectWithValue(error.response);
//     }
//   }
// );

const schedulerConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignUpData: () => {
      return initialState;
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
      state.signUpLoading = "initial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpScheduler.pending, (state) => {
      state.signUpLoading = API_STATUS.PENDING;
    });
    builder.addCase(signUpScheduler.fulfilled, (state, { payload }) => {
      state.signUpLoading = API_STATUS.FULFILLED;
      state.signUpData = payload;
    });
    builder.addCase(signUpScheduler.rejected, (state, action) => {
      state.signUpLoading = API_STATUS.REJECTED;
      console.log(action?.payload, "payload");
      state.errorMessage = action?.payload?.data?.message;
      console.log(state.errorMessage, "state.errorMessage");
    });
  },
});

export const { clearSignUpData, clearErrormsg } = schedulerConfigSlice.actions;

export const signUpSelector = (state) => state.signUpConfiguration;

export default schedulerConfigSlice.reducer;
