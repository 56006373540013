import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LetterById, LetterSelector } from "../store/reducer/LetterReducer";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, CardBody, Card } from "react-bootstrap";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { en } from "../language/en";
import { DatePicker } from "@mui/lab";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import { API_BASE } from "../services/config";

const ViewLetter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { letterData } = useSelector(LetterSelector);
  const [fileName, setfileName] = useState();
  const [voucherfileName, setvoucherfileName] = useState();
  console.log(letterData, "letterData");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LetterById({ id }));
  }, []);

  useEffect(() => {
    if (id && letterData != "") {
      let attData = letterData?.attachments?.split("/");
      let attFileName = attData && attData[3]?.split("_");
      setfileName(attFileName && attFileName[2]);
      let voucherattData = letterData?.voucher_attachments?.split("/");
      let voucherattFileName = voucherattData && voucherattData[3]?.split("_");
      setvoucherfileName(voucherattFileName && voucherattFileName[2]);
    }
  }, [letterData]);

  return (
    <>
      <div className="d-flex justify-content-between mx-2">
        <h2>
          {en.view} {en.letter}
        </h2>
        <Button variant="contained" onClick={() => navigate(-1)}>
          {en.Back}
        </Button>
      </div>
      <Card className="mt-4">
        <CardBody>
          <Row className="mb-3 g-3">
            <Col md="6">
              <p className="mb-1">{en.letter_no}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="content" />
                <Form.Control
                  type="text"
                  name="letter_no"
                  placeholder={en.letter_no}
                  defaultValue={letterData.letter_no}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.letter_date}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Group controlId="formDate">
                  <Form.Control
                    type="text"
                    name="letter_date"
                    placeholder={en.letter_date}
                    defaultValue={dayjs(letterData.letter_date).format(
                      "DD/MM/YYYY"
                    )}
                    className="is-invalid"
                    // onChange={handleChange}
                    disabled
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.enterprise}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="diploma" />
                <Form.Control
                  type="text"
                  name="enterprise"
                  placeholder={en.enterprise}
                  value={letterData.enterprise}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.refer_to}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="user" />
                <Form.Control
                  type="text"
                  name="refer_to"
                  placeholder={en.refer_to}
                  value={letterData.refer_to ?? "-"}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.refer_department}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="building" />
                <Form.Control
                  type="text"
                  name="refer_department"
                  placeholder={en.refer_department}
                  value={letterData.refer_department ?? "-"}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.refer_to_date}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Control
                  type="text"
                  name="refer_to_date"
                  placeholder={en.refer_to_date}
                  value={
                    letterData.refer_to_date
                      ? dayjs(letterData.refer_to_date).format("DD/MM/YYYY")
                      : "-"
                  }
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.voucher_no}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="diploma" />
                <Form.Control
                  type="text"
                  name="voucher_no"
                  placeholder={en.voucher_no}
                  value={letterData.voucher_no ?? "-"}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.voucher_amount}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="dollar" />
                <Form.Control
                  type="text"
                  name="voucher_amount"
                  placeholder={en.voucher_amount}
                  value={letterData.voucher_amount ?? "-"}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>

            <Col md="6">
              <p className="mb-1">{en.voucher_date}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Control
                  type="text"
                  name="voucher_date"
                  placeholder={en.voucher_date}
                  value={
                    letterData.voucher_date
                      ? dayjs(letterData.voucher_date).format("DD/MM/YYYY")
                      : "-"
                  }
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.journal_no}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="content" />
                <Form.Control
                  type="text"
                  name="journal_no"
                  placeholder={en.journal_no}
                  value={letterData.journal_no ?? "-"}
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.journal_date}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Control
                  type="text"
                  name="journal_date"
                  placeholder={en.journal_date}
                  value={
                    letterData.journal_date
                      ? dayjs(letterData.journal_date).format("DD/MM/YYYY")
                      : "-"
                  }
                  className="is-invalid"
                  // onChange={handleChange}
                  disabled
                />
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.attachments}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <div
                  id="fileA"
                  className="text-success d-flex sh-6 mx-2 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                  onClick={() =>
                    window.open(
                      `${API_BASE}/${letterData?.attachments}`,
                      "_blank"
                    )
                  }
                >
                  <CsLineIcons icon="file-text" className="text-white" />
                </div>
                <div htmlFor="fileA" className="mx-2">
                  {" "}
                  {fileName}
                </div>
              </div>
            </Col>
            <Col md="6">
              <p className="mb-1">{en.voucher_attachments}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                {letterData?.voucher_attachments ? (
                  <>
                    <div
                      id="fileVA"
                      className="text-success d-flex sh-6 mx-2 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                      onClick={() =>
                        window.open(
                          `${API_BASE}/${letterData?.voucher_attachments}`,
                          "_blank"
                        )
                      }
                    >
                      <CsLineIcons icon="file-text" className="text-white" />
                    </div>
                    <div htmlFor="fileVA" className="mx-2">
                      {" "}
                      {voucherfileName}
                    </div>
                  </>
                ) : (
                  <div className="mx-2 text-muted">No File to Show...</div>
                )}
              </div>
            </Col>

            <Col md="6">
              <p className="mb-1">{en.subject}:</p>
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="email" />
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  name="subject"
                  disabled
                  defaultValue={letterData.subject}
                  // onChange={handleChange}
                  className="is-invalid"
                  placeholder={en.subject}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ViewLetter;
