import { Card, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Button } from "@mui/material";
import { en } from "../language/en";
import {
  LetterSelector,
  addLetterScheduler,
  clearLetterData,
  getGeneratedLetterNo,
} from "../store/reducer/LetterReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "./layout/Header";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";

const AddLetter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { letter_no, addLetterLoader } = useSelector(LetterSelector);
  const [filePI, setfilePI] = useState({});
  const [initialValues, setInitialValue] = useState({
    letter_no: "",
    subject: "",
    enterprise: "",
    letter_date: "",
    attachments: "",
  });
  const handleForm = (event) => {
    const { files } = event.target;
    setfilePI(files[0]);
  };

  const validationSchema = Yup.object().shape({
    letter_no: Yup.string().required(`${en.letter_no} ${en.is_required}`),
    enterprise: Yup.string().required(`${en.enterprise} ${en.is_required}`),
    subject: Yup.string().required(`${en.subject} ${en.is_required}`),
    letter_date: Yup.string().required(`${en.letter_date} ${en.is_required}`),
    attachments: Yup.string().required(`${en.attachments} ${en.is_required}`),
  });
  const onSubmit = (formData) => {
    dispatch(addLetterScheduler({ formData }));
  };
  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors, setValues } =
    formik;
  // useEffect(() => {
  //   values.letter_no = letter_no;
  // }, [letter_no]);
  console.log(values, "values");

  // useEffect(() => {
  //   dispatch(getGeneratedLetterNo({}));
  // }, []);

  useEffect(() => {
    if (addLetterLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swaladdsuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
  }, [addLetterLoader]);

  const [attachments, setAttachments] = useState();
  const [date, setDate] = useState(dayjs().format("DD/MM/YYYY"));
  return (
    <>
      <Header />
      <div className="d-flex justify-content-between">
        <h1>{en.letter}</h1>
        <Button onClick={() => navigate(-1)} variant="contained">
          {en.Back}
        </Button>
      </div>
      <Card body className="mb-5 mt-3">
        <form
          initialValues={initialValues}
          id="loginForm"
          onSubmit={(e) => {
            e.preventDefault();
            // setValidated(true);
            handleSubmit();
          }}
        >
          <Row className="mb-3 g-3">
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="user" />
                <Form.Control
                  type="text"
                  name="letter_no"
                  placeholder={en.letter_no}
                  defaultValue={values.letter_no}
                  value={values.letter_no}
                  className="is-invalid"
                  onChange={handleChange}
                />
                {errors.letter_no && touched.letter_no && (
                  <Form.Control.Feedback type="invalid">
                    {errors.letter_no}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Group controlId="formDate">
                  <DatePicker
                    selected={values.letter_date}
                    disabled={false}
                    name="letter_date"
                    placeholderText={en.letter_date}
                    // dateFormat="DD/MM/YYYY"
                    onChange={(date) => {
                      setValues({
                        ...values,
                        letter_date: dayjs(date).$d,
                      });
                    }}
                    className="form-control"
                  />
                </Form.Group>
                {errors.letter_date && touched.letter_date && (
                  <div className="text-danger">{errors.letter_date}</div>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="diploma" />
                <Form.Control
                  type="text"
                  name="enterprise"
                  placeholder={en.enterprise}
                  value={values.enterprise}
                  className="is-invalid"
                  onChange={handleChange}
                />
                {errors.enterprise && touched.enterprise && (
                  <Form.Control.Feedback type="invalid">
                    {errors.enterprise}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="file" />
                <Form.Control
                  type="file"
                  name="attachments"
                  className="pt-2 ps-3"
                  onChange={(e) => {
                    const { files } = e.target;
                    setfilePI(files[0]);
                    setValues({ ...values, attachments: files[0] });
                  }}
                  placeholder="Profile Image"
                  values={filePI}
                />
                {errors.attachments && touched.attachments && (
                  <div className="text-danger">{errors.attachments}</div>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="email" />
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  name="subject"
                  defaultValue={values.subject}
                  onChange={handleChange}
                  className="is-invalid"
                  placeholder={en.subject}
                />
                {errors.subject && touched.subject && (
                  <Form.Control.Feedback type="invalid">
                    {errors.subject}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
          </Row>
          <div className="text-end">
            <Button size="lg" type="submit" className="" variant="contained">
              {en.submit}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default AddLetter;
