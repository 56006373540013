import { encryptdata } from "../utils/encrypt&decrypt";
import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", formData);
};

export const signUp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signup", formData);
};

export const letterNo = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/letter/generate_letter_no", Http.getAuthToken());
};

export const createLetter = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/letter/create", formData, Http.getFileHeader());
};

export const letterList = (page, limit, query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/letter/list/?query=" +
      query +
      "&limit=" +
      limit +
      "&page=" +
      page,
    Http.getAuthToken()
  );
};
export const letterById = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/letter/byId/" + id, Http.getAuthToken());
};

export const editReffer = (formData, id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/letter/refer/" + id,
    formData,
    Http.getAuthToken()
  );
};

export const completeProgress = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/letter/complete/",
    formData,
    Http.getFileHeader()
  );
};

export const adminDashboardStat = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/admin/dashboard/", Http.getAuthToken());
};
export const editletter = (formData, id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/letter/edit/" + id,
    formData,
    Http.getFileHeader()
  );
};

export const download_attachment = (formData) => {
  const baseUrl = getBaseEndpointUrl();  
  return Http.post(baseUrl + "/letter/download",formData,Http.getAuthToken());
};