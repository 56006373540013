import { BrowserRouter, Routes, Route } from "react-router-dom";
import useLayout from "./components/layout/Layout";
import "./App.css";
import SignIn from "./components/Signin";
import Letter from "./components/Letter";
import PrivateRoute from "./privateRoute";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import AddLetter from "./components/AddLetter";
import UpdateStatusLetter from "./components/UpdateStatusLetter";
import EditLetter from "./components/EditLetter";
import ViewLetter from "./components/ViewLetter";
function App() {
  const role = localStorage.getItem("role");
  console.log(role, "ASDFASDFASDF");
  useLayout();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/letter" element={<Letter />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/AddLetter/step1" element={<AddLetter />} />
            <Route
              path="/letter_proceed/:id"
              element={<UpdateStatusLetter />}
            />
            <Route path="/letter/view/:id" element={<ViewLetter />} />
            <Route path="/letter/edit/:id" element={<EditLetter />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
