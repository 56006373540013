import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants.js";
import { signIn } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "signInConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signInData: null,
  mentorsignInData: null,
  adminsiginInData: null,
  hiringPartnersignInData: null,
  ccmentorSigninData: [],
  signInLoading: "initial",
  profileLoading: "initial",
  mentorsignInLoading: "initial",
  hiringPartnersignInLoading: "initial",
  ccmentorSignInLoad: "initial",
  updateSchedulerLoading: "initial",
  forgotPasswordLoading: "initial",
  adminsignInLoading: "initial",
  profileData: null,
  count: 0,
};

export const signInScheduler = createAsyncThunk(
  `${namespace}/signInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const signInConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignInData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.signInData = null;
      state.signInLoading = "initial";
      state.mentorsignInData = null;
      state.adminsiginInData = null;
      state.mentorsignInLoading = "initial";
      state.adminsignInLoading = "initial";
      state.errorMessage = null;
      state.hiringPartnersignInData = null;
      state.hiringPartnersignInLoading = "initial";
      state.ccmentorSignInLoad = "initial";
      state.forgotPasswordLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(signInScheduler.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
      console.log(payload, "payload payload");
      let payloadData = JSON.parse(
        EncryptDecrypt.decryptdata(payload, secretKey)
      );
      state.signInData = payloadData;
      console.log(payload, "PAYSDFD");
      localStorage.setItem("authToken", payloadData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payloadData?.name);
      localStorage.setItem("role", payloadData.role);
      state.signInLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(signInScheduler.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearSignInData, clearData } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
