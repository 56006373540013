import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Nav from "./components/layout/nav/Nav";
console.log("inasdsad");
const PrivateRoute = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const isAuthenticated = 1;

  if (!isAuth) {
    localStorage.clear();
  }
  return isAuth ? (
    <>
      <Nav />
      <main>
        <Container>
          <Row className="h-100">
            <Col className="h-100" id="contentArea">
              <Outlet />
            </Col>
          </Row>
        </Container>
      </main>
      {/* <Footer /> */}
      {/* <div id="wrapper">
                <div id="main-content">
                </div>
            </div> */}
    </>
  ) : (
    <Navigate to="/" />
  );
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PrivateRoute);
