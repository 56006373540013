import { Card, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Button } from "@mui/material";
import { en } from "../language/en";
import {
  LetterById,
  LetterSelector,
  addLetterScheduler,
  clearLetterData,
  editLetterScheduler,
  getGeneratedLetterNo,
  completeProgressScheduler,
} from "../store/reducer/LetterReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./layout/Header";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";
import { API_BASE } from "../services/config";

const EditLetter = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    addLetterLoader,
    letterData,
    completeProgressLoader,
    editLetterLoader,
  } = useSelector(LetterSelector);
  const [filePI, setfilePI] = useState({});
  const [fileName, setfileName] = useState("");
  console.log(letterData, "letterData");

  const [referdate, setReferDate] = useState("");
  const [errorMsg, seterrorMsg] = useState({
    referDateError: false,
  });
  const [initialValues, setInitialValue] = useState({
    letter_no: "",
    letter_date: "",
    refer_department: "",
    refer_to_date: "",
    subject: "",
    enterprise: "",
    attachments: "",
    refer_to: "",
  });
  const handleForm = (event) => {
    const { files } = event.target;
    setfilePI(files[0]);
  };

  const referValidation = Yup.object().shape({
    refer_department: Yup.string().required(
      `${en.refer_department} ${en.is_required}`
    ),
    refer_to: Yup.string().required(
      `${en.name_of_the_person} ${en.is_required}`
    ),
    refer_to_date: Yup.date().required(`${en.refer_to_date} ${en.is_required}`),
    subject: Yup.string().required(`${en.subject} ${en.is_required}`),
    enterprise: Yup.string().required(`${en.enterprise} ${en.is_required}`),
  });

  const inprogressValidation = Yup.object().shape({
    subject: Yup.string().required(`${en.subject} ${en.is_required}`),
    enterprise: Yup.string().required(`${en.enterprise} ${en.is_required}`),
    attachments: Yup.string().required(`${en.attachments} ${en.is_required}`),
  });

  useEffect(() => {
    dispatch(LetterById({ id }));
  }, []);

  useEffect(() => {
    if (id && letterData != "") {
      values.letter_no = letterData?.letter_no;
      values.letter_date = letterData?.letter_date;
      values.subject = letterData?.subject;
      values.enterprise = letterData?.enterprise;
      values.refer_to = letterData?.refer_to;
      values.refer_department = letterData?.refer_department;
      values.attachments = letterData?.attachments;
      values.refer_to_date = letterData?.refer_to_date
      setReferDate(letterData?.refer_to_date);
      setfilePI(letterData?.attachments);
      let attData = letterData?.attachments?.split("/");
      let attFileName = attData && attData[3]?.split("_");
      setfileName(attFileName && attFileName[2]);
    }
  }, [letterData]);

  const dateChange = (datevalue) => {
    setReferDate(datevalue);
  };

  const onSubmit = (formData) => {
    // formData.attachments = filePI;
    letterData?.status == 0 && delete formData.refer_to_date;
    typeof formData?.attachments == "string" && delete formData.attachments;
    console.log(formData,'dayjs(date).$d formData')
    dispatch(editLetterScheduler({ formData, id }));
  };
  const formik = useFormik({
    initialValues,
    validationSchema:
      letterData?.status == 1 ? referValidation : inprogressValidation,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, setValues } =
    formik;
  console.log(values, "values");
  console.log(errors, "errors");
  useEffect(() => {
    if (addLetterLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swaladdsuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
    if (editLetterLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swalupdatesuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
    if (completeProgressLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swalupdatesuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
  }, [addLetterLoader, editLetterLoader, completeProgressLoader]);

  const [attachments, setAttachments] = useState();
  const [date, setDate] = useState(dayjs().format("DD/MM/YYYY"));
  return (
    <>
      <Header />
      <div className="d-flex justify-content-between">
        <h1>{en.letter}</h1>
        <Button onClick={() => navigate(-1)} variant="contained">
          {en.Back}
        </Button>
      </div>
      <Card body className="mb-5 mt-3">
        <form
          initialValues={initialValues}
          id="loginForm"
          onSubmit={(e) => {
            e.preventDefault();
            // setValidated(true);
            handleSubmit();
          }}
        >
          <Row className="mb-3 g-3">
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="content" />
                <Form.Control
                  type="text"
                  name="letter_no"
                  placeholder={en.letter_no}
                  defaultValue={values.letter_no}
                  className="is-invalid"
                  onChange={handleChange}
                  disabled
                />
                {errors.letter_no && touched.letter_no && (
                  <Form.Control.Feedback type="invalid">
                    {errors.letter_no}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Group controlId="formDate">
                  <DatePicker
                    selected={values.letter_date}
                    placeholderText={en.letter_date}
                    name="letter_date"
                    // dateFormat="dd/MM/yyyy"
                    className="form-control"
                    disabled
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="diploma" />
                <Form.Control
                  type="text"
                  name="enterprise"
                  placeholder={en.enterprise}
                  value={values.enterprise}
                  className="is-invalid"
                  onChange={handleChange}
                />
                {errors.enterprise && touched.enterprise && (
                  <Form.Control.Feedback type="invalid">
                    {errors.enterprise}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="email" />
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  name="subject"
                  defaultValue={values.subject}
                  onChange={(e) => {
                    const { value } = e.target;
                    setValues({ ...values, subject: value });
                  }}
                  className="is-invalid"
                  placeholder={en.subject}
                />
                {errors.subject && touched.subject && (
                  <Form.Control.Feedback type="invalid">
                    {errors.subject}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>

            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top ">
                <CsLineIcons icon="file" />
                <Form.Control
                  type="file"
                  name="attachments"
                  className="pt-2 ps-3"
                  onChange={(e) => {
                    const { files } = e.target;
                    setValues({ ...values, attachments: files[0] });
                  }}
                  placeholder={en.attachments}
                  multiple={false}
                />
                <label htmlFor="" className="mt-2 mb-2 mx-2">
                  Attachment
                </label>
                <div
                  id="fileA"
                  className="text-success d-flex sh-6 mx-2 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                  onClick={() =>
                    window.open(
                      `${API_BASE}/${letterData?.attachments}`,
                      "_blank"
                    )
                  }
                >
                  <CsLineIcons icon="file-text" className="text-white" />
                </div>
                <div htmlFor="fileA" className="mx-2">
                  {" "}
                  {fileName}
                </div>

                {errors.attachments && touched.attachments && (
                  <div className="text-danger">{errors.attachments}</div>
                )}
              </div>
            </Col>
          </Row>
          <hr />
          {letterData?.status == 1 ? (
            <>
              <Row className="mb-3 g-3">
                <label htmlFor="">Refer To:</label>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="diploma" />
                    <Form.Control
                      type="text"
                      name="refer_to"
                      placeholder={en.name_of_the_person}
                      value={values.refer_to}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.refer_to && touched.refer_to && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_to}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="diploma" />
                    <Form.Control
                      type="text"
                      name="refer_department"
                      placeholder={en.refer_department}
                      value={values.refer_department}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.refer_department && touched.refer_department && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_department}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="calendar" />
                    <Form.Group controlId="formDate">
                      <DatePicker
                        selected={referdate}
                        placeholderText={en.refer_to_date}
                        name="refer_to_date"
                        value={dayjs(values.refer_to_date).format("DD/MM/YYYY")}
                        // dateFormat={"DD/MM/YYYY"}
                        className="form-control"
                        onChange={(date) => {
                          console.log(dayjs(date).$d, "dayjs(date).$d");
                          setValues({
                            ...values,
                            refer_to_date: dayjs(date).$d,
                          });
                        }}
                      />
                    </Form.Group>
                    {errors.refer_to_date && touched.refer_to_date && (
                      <div className="text-danger">{errors.refer_to_date}</div>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <div className="text-end">
            <Button
              size="lg"
              type="submit"
              variant="contained"
              className=""
              onClick={() => {
                if (!referdate) {
                  seterrorMsg({
                    referDateError: !referdate,
                  });
                }
              }}
            >
              {en.submit}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default EditLetter;
