import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import SignInReducer from "./reducer/SignInReducer";
import settingsReducer from "./reducer/settingsSlice";
import menuReducer from "./reducer/menuSlice";
import SignUpReducer from "./reducer/SignUpReducer";
import LetterReducer from "./reducer/LetterReducer";
import DashboardReducer from "./reducer/DashboardReducer";

export const reducer = {
  signInConfiguration: SignInReducer,
  settings: settingsReducer,
  menu: menuReducer,
  signUpConfiguration: SignUpReducer,
  Letter: LetterReducer,
  dashboard: DashboardReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
