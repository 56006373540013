export const en = {
  Welcome: "Welcome",
  lets_start: "let's get started",
  login_info: "Please use your credentials to login",
  login_redirect: "If you are not a member, please",
  register: "Register",
  forgot: "Forgot",
  login: "Login",
  logout: "Logout",
  Dashboards: "Dashboards",
  Letter: "Letter",
  add: "Add",
  letter_no: "Letter No",
  subject: "Subject",
  enterprise: "Enterprise",
  swaladdsuccess: "Added Successfully",
  swalupdatesuccess: "Updated Successfully",
  submit: "Submit",
  Back: "Back",
  journal_no: "Journal No",
  journal_date: "Journal Date",
  journal_note: "Journal Note",
  voucher_no: "Voucher No",
  voucher_date: "Voucher Date",
  voucher_amount: "Voucher Amount",
  voucher_attachments: "Voucher Attachment",
  refer_to: "Name of the person",
  refer_to_date: "Refer Date",
  view: "View",
  letter: "Letter",
  letter_date: "Letter Date",
  s_no: "S.No",
  no: "No",
  date: "Date",
  status: "Status",
  action: "Action",
  total: "Total",
  count: "Count",
  is_required: "is required",
  attachments: "Attachments",
  letters: "Letters",
  refer_department: "Department Name",
  name_of_the_person: "Name of the person",
};
