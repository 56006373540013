import { Button, Form, Col, Row } from "react-bootstrap";
const Header = () => {
  return (
    <>
      <Row>
        <Col>
          <div className="logo position-relative  mb-5">
            <img src="../../img/logo/logo1.png" style={{ width: "150px" }} />
          </div>
        </Col>
        <Col>
          <div className="logo position-relative text-end mb-5">
            <img
              src="../../img/logo/dark-logo (1).png"
              style={{ width: "200px" }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Header;
