import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik, Field } from "formik";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [dobValue, setDobValue] = useState("");
  const [Loginerror, setLoginerror] = useState(false);
  const [collegeValue, setCollgeValue] = useState();
  const [courseValue, setCourseValues] = useState();
  const [yearValue, setYearValues] = useState();
  const [errorMsg, seterrorMsg] = useState({
    dobError: false,
    collegeError: false,
    courseError: false,
    yearError: false,
  });

  useEffect(() => {
    document.body.classList.add("h-100");
    const root = document.getElementById("root");
    if (root) {
      root.classList.add("h-100");
    }
    return () => {
      document.body.classList.remove("h-100");
      if (root) {
        root.classList.remove("h-100");
      }
    };
  }, []);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/[@$!%*?&]/, getCharacterValidationError("special")),
  });
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };
  const onSubmit = (formData) => {
    console.log(formData, "formadata");
    dispatch(signUpScheduler({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const {
    signUpData,
    signUpLoading,
    collegeData,
    collegeLoading,
    errorMessage,
  } = useSelector(signUpSelector);

  useEffect(() => {
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
  }, []);

  const handleDateChange = (date) => {
    console.log(date, "SETDOBDATE");
    setDobValue(date);
  };

  const setSelectedValues = (e, type) => {
    if (type == "college") setCollgeValue(e);
    else if (type == "course") setCourseValues(e);
    else setYearValues(e);
  };

  useEffect(() => {
    console.log(signUpLoading, "signInLoading");
    if (signUpLoading === API_STATUS.FULFILLED) {
      Swal.fire({
        title: "Registerd Successfully!",
        icon: "success",
      });
      dispatch(clearErrormsg());
      navigate("/");
    }
    if (signUpLoading === API_STATUS.REJECTED && errorMessage) {
      Swal.fire({
        title: errorMessage,
        icon: "error",
      });
      setLoginerror(errorMessage);
      dispatch(clearErrormsg());
      // toast.error(errorMessage.error);
    }
  }, [signUpLoading, errorMessage]);

  return (
    <>
      {/* Background Start */}
      <div className="fixed-background" />
      {/* Background End */}

      <div className="container-fluid p-0 h-100 position-relative login_pages">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-100 w-xxl-100">
                <div>
                  <div className="mb-5">
                    {/* <div class="logo "><div class="img" style={{width:"100%",height:"90px"}}></div></div> */}
                    {/* <div
                      className="logo-default h-100 mb-5"
                      style={{ width: "100%", minHeight: "90px" }}
                    /> */}
                    {/* <h1 className="display-3 text-white">
                      Create a Workplace Culture that Inspires and Innovates.
                    </h1> */}
                    <h1 className="display-1 text-white p-5">
                     Audit Letters <br></br> Tracking System
                    </h1>
                    {/* <h1 className="display-3 text-white">
                      Ready for Your Project
                    </h1> */}
                  </div>
                  {/* <p className="h6 text-white lh-1-5 mb-5">
                    Dynamically target high-payoff intellectual capital for
                    customized technologies. Objectively integrate emerging core
                    competencies before process-centric communities...
                  </p>
                  <div className="mb-5">
                    <Button size="lg" variant="outline-white" href="/">
                      Learn More
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Left Side End */}

          {/* Right Side Start */}
          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-75 px-5">
                <div className="mb-1">
                  <NavLink to="/">
                    <img
                      src="../../img/logo/logo1.png"
                      style={{ width: "200px" }}
                    />
                  <img src="../../img/logo/dark-logo (1).png" style={{width:"80%"}} />
                  </NavLink>
                  {/* <h2 className="cta-1 mb-0 text-primary">
                    Welcome to Culture Catalyst,
                  </h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2> */}
                </div>
                <div className="mb-5">
                  <p className="h6">
                    Already have an account?{" "}
                    <NavLink to="/" className="login_txt">
                      Signin
                    </NavLink>
                    .
                  </p>
                </div>
                <div>
                  <form
                    id="loginForm"
                    className={
                      validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                    }
                    onSubmit={(e) => {
                      e.preventDefault();
                      setValidated(true);
                      handleSubmit();
                    }}
                  >
                    <div className="mb-3 filled form-group tooltip-end-top">
                      {Loginerror && Loginerror.error && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {Loginerror.error}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <Row className="mb-3 g-3">
                      <Col md="12">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="user" />
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={values.name}
                            className="is-invalid"
                            onChange={handleChange}
                          />
                          {errors.name && touched.name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            className="is-invalid"
                            placeholder="Email"
                          />
                          {errors.email && touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="mb-3 filled form-group tooltip-end-top">
                          <CsLineIcons icon="lock-off" />
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            className="is-invalid"
                            placeholder="Create your own Password"
                          />
                          {errors.password && touched.password && (
                            <Form.Control.Feedback type="invalid">
                              {errors.password}
                            </Form.Control.Feedback>
                          )}
                          <p className="py-1">
                            Use Upper case, Lower case, Numbers &
                            Symbols.Password length should be minimum 8
                            characters.
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      {/* <Button size="lg" type="submit"> */}
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={
                          ![API_STATUS.FULFILLED, "initial"].includes(
                            signUpLoading
                          )
                        }
                      >
                        Register
                      </LoadingButton>
                      {/* </Button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
