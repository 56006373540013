import { Card, Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CsLineIcons from "./cs-line-icons/CsLineIcons";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Button } from "@mui/material";
import { en } from "../language/en";
import {
  LetterById,
  LetterSelector,
  addLetterScheduler,
  downloadAttachment,
  clearLetterData,
  editRefferScheduler,
  getGeneratedLetterNo,
  completeProgressScheduler,
} from "../store/reducer/LetterReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "./layout/Header";
import { API_STATUS } from "../utils/Constants";
import Swal from "sweetalert2";
import { API_BASE } from "../services/config";
const UpdateStatusLetter = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    addLetterLoader,
    letterData,
    editRefferLoader,
    completeProgressLoader,
  } = useSelector(LetterSelector);
  const [filePI, setfilePI] = useState({});
  const [attachmentFile, setattachmentFile] = useState({});

  const [referdate, setReferDate] = useState("");
  const [journaldate, setJournalDate] = useState("");
  const [voucherdate, setVoucherDate] = useState("");
  const [fileName, setfileName] = useState("");

  const [errorMsg, seterrorMsg] = useState({
    referDateError: false,
    voucherDateError: false,
    journalDateError: false,
  });
  const [initialValues, setInitialValue] = useState({
    letter_no: "",
    letter_date: "",
    refer_to: "",
    refer_department: "",
    refer_to_date: "",
    voucher_no: "",
    voucher_date: "",
    voucher_amount: "",
    journal_note: "",
    voucher_attachments: "",
  });
  const handleForm = (event) => {
    const { files } = event.target;
    setfilePI(files[0]);
  };

  const referValidation = Yup.object().shape({
    refer_to: Yup.string().required(`${en.refer_to} ${en.is_required}`),
    refer_department: Yup.string().required(
      `${en.refer_department} ${en.is_required}`
    ),
  });

  const completeValidation = Yup.object().shape({
    voucher_no: Yup.string().required(`${en.voucher_no} ${en.is_required}`),
    voucher_amount: Yup.string().required(
      `${en.voucher_amount} ${en.is_required}`
    ),
    voucher_date: Yup.string().required(`${en.voucher_date} ${en.is_required}`),
    voucher_attachments: Yup.string().required(
      `${en.voucher_attachments} ${en.is_required}`
    ),
  });

  useEffect(() => {
    dispatch(LetterById({ id }));
  }, []);

  useEffect(() => {
    if (id && letterData != "") {
      values.letter_no = letterData?.letter_no;
      values.letter_date = letterData?.letter_date;
      values.subject = letterData?.subject;
      values.enterprise = letterData?.enterprise;
      values.refer_to = letterData?.refer_to;
      values.refer_department = letterData?.refer_department;
      setReferDate(letterData?.refer_to_date);
      let attData = letterData?.attachments?.split("/");
      let attFileName = attData && attData[3]?.split("_");
      console.log(attFileName, "SDFDFGDFG");
      setfileName(attFileName && attFileName[2]);
    }
  }, [letterData]);

  const dateChange = (datevalue, type) => {
    if (type == "voucher_date") {
      setVoucherDate(datevalue);
    } else {
      setReferDate(datevalue);
    }
  };
  const CompleteForm = (formData) => {
    console.log(formData, "COMPLETEFORM");
  };

  const onSubmit = (formData) => {
    console.log(formData, "DSFSDFGSDF");
    if (letterData?.status == 0) {
      delete formData?.journal_note;
      delete formData?.voucher_date;
      delete formData?.voucher_no;
      delete formData?.letter_no;
      delete formData?.letter_no;
      delete formData?.letter_date;
      delete formData?.subject;
      delete formData?.enterprise;
      delete formData?.voucher_amount;
      delete formData?.voucher_attachments;
      delete formData?.enterprise;
      delete formData?.attachments;
      formData.refer_to_date = referdate;

      console.log(formData, "values formdata");
      dispatch(editRefferScheduler({ formData, id }));
    } else {
      delete formData?.refer_to;
      delete formData?.letter_no;
      delete formData?.letter_date;
      delete formData?.refer_to_date;
      delete formData?.subject;
      delete formData?.enterprise;
      formData.letter_id = id;
      dispatch(completeProgressScheduler({ formData }));
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema:
      letterData?.status == 0 ? referValidation : completeValidation,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors, setValues } =
    formik;
  useEffect(() => {
    if (addLetterLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swaladdsuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
    if (editRefferLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swalupdatesuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
    if (completeProgressLoader === API_STATUS.FULFILLED) {
      Swal.fire({
        icon: "success",
        title: en.swalupdatesuccess,
      }).then(() => {
        navigate(-1);
        dispatch(clearLetterData());
      });
    }
  }, [addLetterLoader, editRefferLoader, completeProgressLoader]);

  const [attachments, setAttachments] = useState();
  const [date, setDate] = useState(dayjs().format("DD/MM/YYYY"));
  console.log(values, initialValues, "values formik");

  const handleDownload = (path) => {
    let formData = {};
    formData.filepath = path;
    dispatch(downloadAttachment({ formData }));
  };

  return (
    <>
      <Header />
      <div className="d-flex justify-content-between">
        <h1>{en.letter}</h1>
        <Button onClick={() => navigate(-1)} variant="contained">
          {en.Back}
        </Button>
      </div>
      <Card body className="mb-5 mt-3">
        <form
          initialValues={initialValues}
          id="loginForm"
          onSubmit={(e) => {
            e.preventDefault();
            // setValidated(true);
            handleSubmit();
          }}
        >
          <Row className="mb-3 g-3">
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="content" />
                <Form.Control
                  type="text"
                  name="letter_no"
                  placeholder={en.letter_no}
                  disabled
                  defaultValue={values.letter_no}
                  className="is-invalid"
                  onChange={handleChange}
                />
                {errors.letter_no && touched.letter_no && (
                  <Form.Control.Feedback type="invalid">
                    {errors.letter_no}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="calendar" />
                <Form.Group controlId="formDate">
                  <DatePicker
                    selected={values.letter_date}
                    placeholderText={en.letter_date}
                    name="letter_date"
                    disabled
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="diploma" />
                <Form.Control
                  type="text"
                  name="enterprise"
                  placeholder={en.enterprise}
                  value={values.enterprise}
                  className="is-invalid"
                  onChange={handleChange}
                  disabled
                />
                {errors.enterprise && touched.enterprise && (
                  <Form.Control.Feedback type="invalid">
                    {errors.enterprise}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="email" />
                <Form.Control
                  as={"textarea"}
                  rows={4}
                  name="subject"
                  defaultValue={values.subject}
                  onChange={handleChange}
                  className="is-invalid"
                  placeholder={en.subject}
                  disabled
                />
                {errors.subject && touched.subject && (
                  <Form.Control.Feedback type="invalid">
                    {errors.subject}
                  </Form.Control.Feedback>
                )}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3 filled form-group tooltip-end-top">
                <CsLineIcons icon="file" />
                <label htmlFor="">Attachment</label>                
                <div
                id="fileA"
                  className="text-success d-flex sh-6 mx-2 sw-6 bg-gradient-light align-items-center justify-content-center rounded-xl"
                  onClick={() =>
                    window.open(
                      `${API_BASE}/${letterData?.attachments}`,
                      "_blank"
                    )
                  }
                >
                  <CsLineIcons icon="file-text" className="text-white" />
                </div>
                <div htmlFor="fileA" className="mx-2"> {fileName}</div>

              </div>
            </Col>
          </Row>
          <hr />

          {letterData?.status === 0 ? (
            <>
              <Row className="mb-3 g-3">
                <label htmlFor="">Refer To:</label>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="user" />
                    <Form.Control
                      type="text"
                      name="refer_to"
                      placeholder={en.refer_to}
                      value={values.refer_to}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.refer_to && touched.refer_to && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_to}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="building" />
                    <Form.Control
                      type="text"
                      name="refer_department"
                      placeholder={en.refer_department}
                      value={values.refer_department}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.refer_department && touched.refer_department && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_department}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="calendar" />
                    <Form.Group controlId="formDate">
                      <DatePicker
                        selected={referdate}
                        placeholderText={en.refer_to_date}
                        name="refer_to_date"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        onChange={(date) => {
                          dateChange(date, "refer_to_date");
                        }}
                      />
                    </Form.Group>
                    {errorMsg?.referDateError && (
                      <p className="text-danger">
                        {en.refer_to_date} is required
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <hr />
            </>
          ) : (
            <>
              <Row className="mb-3 g-3">
                <label htmlFor="">Refer To:</label>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="user" />
                    <Form.Control
                      type="text"
                      name="refer_to"
                      placeholder={en.refer_to}
                      value={values.refer_to}
                      className="is-invalid"
                      onChange={handleChange}
                      disabled
                    />
                    {errors.refer_to && touched.refer_to && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_to}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="building" />
                    <Form.Control
                      type="text"
                      name="refer_department"
                      placeholder={en.refer_department}
                      value={values.refer_department}
                      className="is-invalid"
                      onChange={handleChange}
                      disabled
                    />
                    {errors.refer_department && touched.refer_department && (
                      <Form.Control.Feedback type="invalid">
                        {errors.refer_department}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="calendar" />
                    <Form.Group controlId="formDate">
                      <DatePicker
                        selected={referdate}
                        placeholderText={en.refer_to_date}
                        name="refer_to_date"
                        disabled
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Form.Group>
                  </div>
                </Col>
                <hr />

                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="diploma" />
                    <Form.Control
                      type="text"
                      name="voucher_no"
                      placeholder={en.voucher_no}
                      value={values.voucher_no}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.voucher_no && touched.voucher_no && (
                      <Form.Control.Feedback type="invalid">
                        {errors.voucher_no}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                {/* <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="calendar" />
                    <Form.Group controlId="formDate">
                      <DatePicker
                        selected={voucherdate}
                        placeholderText={en.voucher_date}
                        name="voucher_date"
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        onChange={(date) => {
                          dateChange(date, "voucher_date");
                        }}
                      />
                    </Form.Group>
                    {errorMsg?.voucherDateError && (
                      <p className="text-danger">
                        {en.voucher_date} is required
                      </p>
                    )}
                  </div>
                </Col> */}
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="calendar" />
                    <Form.Group controlId="formDate">
                      <DatePicker
                        selected={values.voucher_date}
                        disabled={false}
                        name="voucher_date"
                        placeholderText={en.voucher_date}
                        // dateFormat="DD/MM/YYYY"
                        onChange={(date) => {
                          setValues({
                            ...values,
                            voucher_date: dayjs(date).$d,
                          });
                        }}
                        className="form-control"
                      />
                    </Form.Group>
                    {errors.voucher_date && touched.voucher_date && (
                      <div className="text-danger">{errors.voucher_date}</div>
                    )}
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="dollar" />
                    <Form.Control
                      type="number"
                      name="voucher_amount"
                      placeholder={en.voucher_amount}
                      value={values.voucher_amount}
                      className="is-invalid"
                      onChange={handleChange}
                    />
                    {errors.voucher_amount && touched.voucher_amount && (
                      <Form.Control.Feedback type="invalid">
                        {errors.voucher_amount}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="file" />
                    <Form.Control
                      type="file"
                      name="voucher_attachments"
                      className="pt-2 ps-3"
                      onChange={(e) => {
                        const { files } = e.target;
                        setattachmentFile(files[0]);
                        setValues({ ...values, voucher_attachments: files[0] });
                      }}
                      placeholder="Voucher Attachment"
                    />
                    {errors.voucher_attachments &&
                      touched.voucher_attachments && (
                        <div className="text-danger">
                          {errors.voucher_attachments}
                        </div>
                      )}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3 filled form-group tooltip-end-top">
                    <CsLineIcons icon="email" />
                    <Form.Control
                      as={"textarea"}
                      rows={4}
                      name="journal_note"
                      defaultValue={values.journal_note}
                      onChange={handleChange}
                      className="is-invalid"
                      placeholder={en.journal_note}
                    />
                    {errors.journal_note && touched.journal_note && (
                      <Form.Control.Feedback type="invalid">
                        {errors.journal_note}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="text-end">
            <Button
              size="lg"
              type="submit"
              variant="contained"
              className=""
              onClick={() => {
                if (!referdate) {
                  seterrorMsg({
                    referDateError: !referdate,
                  });
                }
              }}
            >
              {en.submit}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};

export default UpdateStatusLetter;
